<template>
  <div id="v-pills-education" role="tabpanel" aria-labelledby="v-pills-personal-tab">
    <div class="col content">
      <h5 class="text-primary p-3">Highest education qualification details</h5>
      <hr />
      <div class="p-4" id="display_profile" v-if="this.user.high_qualification">
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Course<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="highest_education.course_id">{{getCourse(highest_education.course_id)}}</p>
            <p v-else>-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Specification<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="highest_education.specification">{{getSpecification(highest_education.specification)}}</p>
            <p v-else>-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">State<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="highest_education.state_id">{{getState(highest_education.state_id)}}</p>
            <p v-else>-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">University/College<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="highest_education.university_id">{{getUniversity(highest_education.university_id)}}</p>
            <p v-else>-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Course Type<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="highest_education.course_type">{{getCourseType(highest_education.course_type)}}</p>
            <p v-else>-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="highest_education.passout_year">{{highest_education.passout_year}}</p>
            <p v-else>-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="highest_education.mark">{{highest_education.mark}}%</p>
            <p v-else>-</p>
          </div>
        </div>
      </div>
      <h5 class="text-primary p-3">More qualification details<span class="float-right"></span></h5> 
      <hr />     
      <div class="p-4" id="display_profile" v-if="this.user.more_qualification">     
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">School<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xiith_details.xiith_school_name">{{xiith_details.xiith_school_name}}</p>
            <p v-if="!xiith_details.xiith_school_name">-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">CBSE/State/ICSC<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xiith_details.xiith_board_name">{{xiith_details.xiith_board_name}}</p>
            <p v-if="!xiith_details.xiith_board_name">-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xiith_details.xiith_passout_year">{{xiith_details.xiith_passout_year}}</p>
            <p v-if="!xiith_details.xiith_passout_year">-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xiith_details.xiith_marks">{{xiith_details.xiith_marks}}%</p>
            <p v-if="!xiith_details.xiith_marks">-</p>
          </div>
        </div>
      </div>
      <h5 class="text-primary p-3">10th details<span class="float-right"></span></h5>           
      <hr />
      <div class="p-4" id="display_profile" v-if="this.user.xth_details">
        <div class="row mb-2">
          <div class="col-4">
            <label class="text-secondary">Alternate Contact Number</label>
          </div>
          <div class="col-8">
            <p v-if="this.user.personal_details.contact_no">{{ this.user.personal_details.contact_no }}</p>
            <p v-if="!this.user.personal_details.contact_no">-</p>
          </div>
        </div><div class="row">
        <div class="col-lg-4"><label class="text-secondary">School<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xth_details.xth_school_name">{{xth_details.xth_school_name}}</p>
            <p v-if="!xth_details.xth_school_name">-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">CBSE/State/ICSC<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xth_details.xth_board_name">{{xth_details.xth_board_name}}</p>
            <p v-if="!xth_details.xth_board_name">-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Passing Year<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xth_details.xth_passout_year">{{xth_details.xth_passout_year}}</p>
            <p v-if="!xth_details.xth_passout_year">-</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4"><label class="text-secondary">Marks<span style="color: red">*</span></label></div>
          <div class="col-lg-8">
            <p v-if="xth_details.xth_marks">{{xth_details.xth_marks}}%</p>
            <p v-if="!xth_details.xth_marks">-</p>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import Actions from '../../libraries/apiActions.js';
export default {
  name: "candidate_profile",
  props: ["user"],
  data: function () {
    return {
      // validate: new this.$valid(),
      action: new Actions(),
      states:[],
      courses:[],
      spec:[],
      institutes:[],
      xiith_passout:null,
      xth_passout:null,
      highest_passout:null,
      more_passout:null,
      c_type:null,
      highest_education:{
        course_id : null,
        specification : null,
        state_id : null,
        university_id : null,
        course_type : null,
        passout_year : null,
        passout_month:null,
        mark : null
      },
      highest_qualification:{
        course:null,
        specification : null,
        state:null,
        university:null,
        course_type:null
      },
      more_qualification:{
        course_id:null,
        specification_id:null,
        state_id:null,
        university_id:null,
        course_type:null,
        passing_year:null,
        passing_month:null,
        mark:null
      },
      more_quals:[],
      xiith_details:{
        xiith_school_name:null,
        xiith_board_name:null,
        xiith_passout_year:null,
        xiith_passout_month:null,
        xiith_marks:null,
      },
      xth_details:{
        xth_school_name:null,
        xth_board_name:null,
        xth_passout_year:null,
        xth_passout_month:null,
        xth_marks:null,
      },
      course_types:[
        {value:1, text:'Full time'},
        {value:2, text:'Part time',}
      ]
    };
  },
  beforeMount(){
      this.getStates();
      this.setHighestQualification();
      this.courses = this.$store.state.courses.courses.courses;
      console.log(this.courses);
      this.setXiithDetails();
      this.setXthDetails();
      this.more_quals = this.user?.more_qualification;
  },
  methods: {
    getCourseType(data){
        this.course_types.forEach(type => {
          if(type.value == data){
            this.c_type = type.text;
          }
        });
        return this.c_type;
      },
    getCourse(data){
      this.courses.forEach(course => {
        if(course.id == data){
          this.set_course =course.text;
        }
      });
      return this.set_course;
    },
    getState(data){
      this.states.forEach(state => {
        if(state.id == data){
          this.set_state = state.text;
        }
      });
      return this.set_state;
    },
    getSpecification(data){
      console.log(data);
      this.spec.forEach(s => {
        if(s.value == data){
          this.set_specification = s.text;
        }
      });
      return this.set_specification;
    },
    getUniversity(data){
      this.institutes.forEach(uni => {
        if(uni.value == data){
          this.set_institute = uni.text;
        }
      });
      return this.set_institute;
    },
    getStates(){
      this.action.getStates().then(data => {
        data.states.forEach((element) => {
          this.states.push({
            id: element.state_id,
            text:element.state_name
          })
        })
      })
    },
    getSpecifications: function () {
      this.spec = [];
      this.action.getSpec(this.highest_education.course_id).then((data) => {
        data.specifications.forEach((element) => {
          this.spec.push({
            value: element.bid,
            text: element.branch_name,
          });
        });
      });
    },
    getInstitutes: function () {
      this.institutes = [];
      this.action.getInstitutes({state_id:this.highest_education.state_id}).then((data) => {
        if(data.institutes){
          data.institutes.forEach((element) => {
            this.institutes.push({
              value: element.institute_id,
              text:element.institute_name
            })
          })
        }
      })
    },
    setHighestQualification(){
      this.highest_education.course_id = (this.user.high_qualification?.course?.course_id)?this.user.high_qualification?.course?.course_id:null;
      this.highest_education.specification =  (this.user.high_qualification?.branch?.bid)?this.user.high_qualification?.branch?.bid:null;
      this.getSpecifications();
      this.highest_education.state_id = (this.user.high_qualification?.state?.state_id)?this.user.high_qualification?.state?.state_id:null;
      this.getInstitutes();
      this.highest_education.university_id = (this.user.high_qualification?.university?.university_id)?this.user.high_qualification?.university?.university_id:null;
      this.highest_education.course_type = (this.user.high_qualification?.course_type)?this.user.high_qualification?.course_type:null;
      this.highest_education.passout_year = (this.user.high_qualification?.passout_year)?this.user.high_qualification?.passout_year:null;
      this.highest_education.passout_month = (this.user.high_qualification?.passout_month)?this.user.high_qualification?.passout_month:null;
      this.highest_education.mark = (this.user.high_qualification?.aggregate_marks)?this.user.high_qualification?.aggregate_marks:null;
      this.highest_passout = (this.highest_education.passout_month && this.highest_education.passout_year)? ((this.highest_education.passout_month == '11' ||this.highest_education.passout_month == '12')?this.highest_education.passout_year+'-'+this.highest_education.passout_month:this.highest_education.passout_year+'-0'+this.highest_education.passout_month): null;
      this.highest_qualification.specification = (this.user.high_qualification?.branch?.bid)?this.user.high_qualification?.branch?.branch_name:null;
      this.highest_qualification.state = (this.user.high_qualification?.state?.state_name)?this.user.high_qualification?.state?.state_name:null;
      this.highest_qualification.university = (this.user.high_qualification?.university?.university_name)?this.user.high_qualification?.university?.university_name:null;
      this.course_types.forEach(type => {
        if(type.value == this.highest_education.course_type){
          this.highest_qualification.course_type = type.text;
        }
      });
    },
    setXthDetails(){
      this.xth_details.xth_school_name = (this.user.xth_details?.xth_school_name) ? this.user.xth_details?.xth_school_name : null;
      this.xth_details.xth_board_name = (this.user.xth_details?.xth_board_name) ? this.user.xth_details?.xth_board_name : null;
      this.xth_details.xth_passout_year = (this.user.xth_details?.xth_passout_year) ? this.user.xth_details?.xth_passout_year : null;
      this.xth_details.xth_passout_month = (this.user.xth_details?.xth_passout_month) ? this.user.xth_details?.xth_passout_month : null;
      this.xth_details.xth_marks = (this.user.xth_details?.xth_marks) ? this.user.xth_details?.xth_marks : null;
      this.xth_passout = (this.xth_details.xth_passout_month && this.xth_details.xth_passout_year)? ((this.xth_details.xth_passout_month == '11' ||this.xth_details.xth_passout_month == '12')?this.xth_details.xth_passout_year+'-'+this.xth_details.xth_passout_month:this.xth_details.xth_passout_year+'-0'+this.xth_details.xth_passout_month): null;
    },
    setXiithDetails(){
      this.xiith_details.xiith_school_name = (this.user.xiith_details?.xiith_school_name) ? this.user.xiith_details?.xiith_school_name : null;
      this.xiith_details.xiith_board_name = (this.user.xiith_details?.xiith_board_name) ? this.user.xiith_details?.xiith_board_name : null;
      this.xiith_details.xiith_passout_year = (this.user.xiith_details?.xiith_passout_year) ? this.user.xiith_details?.xiith_passout_year : null;
      this.xiith_details.xiith_passout_month = (this.user.xiith_details?.xiith_passout_month) ? this.user.xiith_details?.xiith_passout_month : null;
      this.xiith_details.xiith_marks = (this.user.xiith_details?.xiith_marks) ? this.user.xiith_details?.xiith_marks : null;
      this.xiith_passout = (this.xiith_details.xiith_passout_month && this.xiith_details.xiith_passout_year)? ((this.xiith_details.xiith_passout_month == '11' ||this.xiith_details.xiith_passout_month == '12')?this.xiith_details.xiith_passout_year+'-'+this.xiith_details.xiith_passout_month:this.xiith_details.xiith_passout_year+'-0'+this.xiith_details.xiith_passout_month): null;
    },
  },
};
</script>

<style>
.edit-button{
  position: absolute;
  right: 15px;
  top: 25px;
  cursor: pointer;
}
.edit-button img{
  width: 25px;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
  min-height:55px !important;
}
.v-text-field__details{
  display: none !important;
}
.v-input__slot{
  margin-bottom:0 !important;
}
</style>
<style scoped>
input{
  padding: 28px 0px 28px 15px !important;
}
.error-feedback{
  position: absolute;
}
</style>